import React, { Component } from "react"
import { Link } from "gatsby"

export default class Sidebar extends Component {
  render() {
    return (
      <nav className="navbar navbar-expand-lg members-menu mean-container">
        <div className="container">
          <button
            type="button"
            data-toggle="collapse"
            className="navbar-toggler"
            data-target="#navbarMembers"
          >
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <div className="collapse navbar-collapse mean-nav" id="navbarMembers">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link className="nav-link" to="/members/members">
                  Members
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/members/events">
                  Events
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/members/banners">
                  Banners
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/members/sponsors">
                  Sponsors
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/members/food-items">
                  Food Items
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    )
  }
}
