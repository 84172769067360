import React, { Component } from "react"
import DataTable from "react-data-table-component"
import axios from "axios"
import apiRequest from "../../hooks/HttpRequest"
import { ToastContainer, toast } from "react-toastify"
import Sidebar from "../../layouts/Sidebar"
import "react-toastify/dist/ReactToastify.css"
import { isLoggedIn, getUser } from "../../services/auth"
import Moment from "moment";

let gridColumns
export default class OrderHistory extends Component {
    constructor(props) {
        super(props)

        this.state = {
            orderArray: [],

            // //CreatedBy: sessionStorage.getItem("UserName"),
            //UpdatedBy: sessionStorage.getItem("UserName"),
        }
    }
    componentDidMount() {
        gridColumns = [
            {
                name: "Event Name",
                selector: "EventName",
                sortable: true,
                width: '20%'
            },
            {
                name: "Event Date & Time",
                selector: "EDate",
                cell: (row) => <span>{Moment(row.EDate).format("MM-DD-YYYY") + " " + row.ETime}</span>,
                sortable: true,
                width: '15%'
            },
            {
                name: "Purchases",
                selector: "Purchases",
                width: '55%'
            },
            {
                name: "Total",
                selector: "Total",
                sortable: true,
                cell: (row) => <span>{row.Total.toFixed(2)}</span>,
                right: true,
                width: '10%'
            },
        ]

        this.loadOrders()
    }

    //handles form inputs change events
    handleChange = (e) => {
        if (e.target.type === "checkbox") {
            this.setState({ [e.target.name]: e.target.checked ? true : false })
        } else {
            this.setState({ [e.target.name]: e.target.value })
        }
    }

    //handles form file input change event
    onFileChange = (e) => {
        this.setState({
            PhotoURL: e.target.files[0],
            previewImage: URL.createObjectURL(e.target.files[0]),
        })
    }

    loadOrders() {
        // var memberID = getUser().KyTSUser.MemberID
        //// console.log(getUser().KyTSUser)
        //// console.log(getUser().KyTSUser.MemberID)
        axios
            .get(
                apiRequest.baseURL + "Purchases/GetPurchasesByMember/" + getUser().KyTSUser.MemberID,
                apiRequest.jsonHeader
            )
            .then((response) => {
                // console.log(response.data.Member)
                this.setState({
                    orderArray: response.data.Purchases
                })
            })
            .catch((e) => {
                toast.error(e)
                // console.log(e)
            })
    }

    render() {
        return (
            <>
                {isLoggedIn() && getUser().KyTSUser.IsAdmin ? (
                    <Sidebar></Sidebar>
                ) : null}
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-12">
                            <div className="widget-content widget-content-area br-6">
                                {/* ----------------------------------------------------------------------------------------------------- */}
                                {/* List Category Grid Starts */}
                                <div className="row">
                                    <div className="col-md-3">
                                        <h3>Order History</h3>
                                    </div>
                                    <div className="col-md-5"></div>
                                    <div className="col-md-4 text-right">

                                    </div>
                                </div>
                                <ToastContainer />
                                <div id="grid">
                                    <DataTable
                                        columns={gridColumns}
                                        pagination
                                        highlightOnHover
                                        responsive
                                        data={this.state.orderArray}
                                    />
                                </div>
                                {/* List Category Grid Ends */}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
