import React from "react"
import Layout from "../../components/layout"
import OrderHistoryPage from "../../components/members/orderHistory"

const MyOrderHistory = () => {
    return (
        <Layout>
            <OrderHistoryPage />
        </Layout>
    )
}

export default MyOrderHistory

